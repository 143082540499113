import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { TMenuItem } from '../../../common/types';
import { TTheme } from '../../../common/types/common.type';
import RecommendationCarouselItem from './recomendation-carousel-item.component';

export type Props = {
  recommendations: TMenuItem[];
  theme: TTheme;
  onAddMenuItem: (menuItem: TMenuItem) => void;
};

const RecommendationsCarousel: React.FC<Props> = ({ recommendations, theme, onAddMenuItem }) => {
  return (
    <div>
      <CarouselProvider
        naturalSlideWidth={200}
        naturalSlideHeight={70}
        totalSlides={recommendations.length}
        visibleSlides={1} // Adjust based on how many items you want to show at a time
        className="relative"
        infinite
      >
        <Slider>
          {recommendations.map((recommendation, index) => (
            <Slide key={recommendation.item_id} index={index}>
              <div className="mx-16">
                <RecommendationCarouselItem
                  key={recommendation.menu_item_external_id}
                  menuItem={recommendation}
                  imageSize={undefined}
                  theme={theme}
                  onAddMenuItem={onAddMenuItem}
                />
              </div>
            </Slide>
          ))}
        </Slider>
        <ButtonBack
          className="absolute top-1/2 -translate-y-1/2 mt-[-10px] left-2 transform px-2 py-2 rounded-[4px]"
          style={{
            backgroundColor: theme?.general?.cardBackgroundColor || '',
          }}
        >
          <ChevronLeft style={{ color: theme.general?.textColor || '' }} />
        </ButtonBack>
        <ButtonNext
          className="absolute top-1/2 -translate-y-1/2 mt-[-10px] right-2 transform px-2 py-2 rounded-[4px]"
          style={{
            backgroundColor: theme?.general?.cardBackgroundColor || '',
          }}
        >
          <ChevronRight style={{ color: theme.general?.textColor || '' }} />
        </ButtonNext>
      </CarouselProvider>
    </div>
  );
};

export default RecommendationsCarousel;
