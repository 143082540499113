/* eslint-disable jsx-a11y/no-redundant-roles */
import { MinusCircle, PlusCircle } from 'lucide-react';
import { notification } from 'antd';
import { TrashIcon } from '@heroicons/react/24/outline';
import { isMobile } from 'react-device-detect';
import { useContext, useEffect, useState } from 'react';
import utils, { cn } from '../../../common/services/utils.service';
import useOrderCreationStore from '../../../hooks/useOrderCreation.hook';
import { TOrderLineItem } from '../../../common/types';
import { useIsLoading, useRecommendationModal } from '../../../common/hooks';
import sharedApi, { TManualCombinationRecommendation } from '../../../common/shared.api';
import { ThemeContext } from '../../../../contexts/theme.context';

type Props = {
  containerStyle?: string;
  subTotalHidden?: boolean;
  showManualCombinationRecommendations?: boolean;
  allowQuantityChange?: boolean;
};

const BasketProductsList = ({
  containerStyle,
  subTotalHidden,
  showManualCombinationRecommendations,
  allowQuantityChange,
}: Props) => {
  const { theme } = useContext(ThemeContext);
  const { setIsLoading } = useIsLoading();
  const { setRecommendationsStore } = useRecommendationModal();
  const [manualCombinationRecommendation, setManualCombinationRecommendation] = useState<
    TManualCombinationRecommendation[]
  >([]);
  const { orderCreationStore, changeLineItemQuantity } = useOrderCreationStore();
  const { orderTicket, menu } = orderCreationStore;
  const basketItemIds = orderTicket.item_list.map((item) => Number(item.item_id));

  const checkMenuItemRecommendation = async (menuItemIds: number[]) => {
    const { data, status } = await sharedApi.getMenuItemRecommendationByItemIds(menuItemIds);

    if (status === 200 && data.status === 'successful' && data.data.length > 0) {
      setManualCombinationRecommendation(data.data.filter((r) => r.customer_recommendation_p));
    }
  };

  const onQuantityChange = async (item: TOrderLineItem, quantity: number | 'remove') => {
    setIsLoading(true);
    const itemUpdated = await changeLineItemQuantity(item, quantity);
    if (!itemUpdated) {
      notification.error({
        message: 'Something went wrong',
        description: `Unable to change quantity of ${item.display_name_en}, please try again later`,
        placement: isMobile ? 'bottom' : 'topRight',
        duration: 1.5,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (showManualCombinationRecommendations && orderTicket.item_list.length > 0) {
      checkMenuItemRecommendation(orderTicket.item_list.map((item) => Number(item.item_id)));
    }
  }, [showManualCombinationRecommendations, orderTicket.item_list]);

  return (
    <div className={cn('flex flex-col gap-0.5 px-1.5', containerStyle)}>
      {orderTicket.item_list.map((product) => (
        <div
          key={product.id}
          className="p-2 mt-2 bg-white rounded-md shadow-md border"
          style={{
            backgroundColor: theme?.general?.cardBackgroundColor || '',
            borderColor: theme?.general?.cardBackgroundColor || '',
          }}
        >
          <div className="grid grid-cols-8 md:grid-cols-12 gap-0.5">
            <div className="flex w-full justify-center">
              <img
                src={
                  product?.menu_item_image
                    ? product?.menu_item_image
                    : `${process.env.PUBLIC_URL}/assets/products/empty_menu.png`
                }
                alt={product.display_name_en}
                className="w-full h-full aspect-square object-cover rounded-lg"
                onError={(e) => {
                  e.currentTarget.src = `/assets/products/empty_menu.png`;
                }}
              />
            </div>
            <div className="col-span-7 md:col-span-11">
              <div className="grid grid-cols-6 pl-0">
                <div className="col-span-4">
                  <span className="font-bold">{product.quantity}x </span>
                  <span className="capitalize">{product.display_name_en}</span>
                </div>
                <div className="col-span-2 text-right mr-4">
                  {utils.formatCurrency(Number(product.priceToShow), product.currency_alpha_3)}
                </div>
              </div>
              <ul>
                {product.modifier_section_list.map((modifier) => (
                  <div key={modifier.external_modifier_section_id} className="pl-0 ml-2">
                    <h5 className="mb-0 capitalize font-bold">{modifier.display_section_name_en}</h5>
                    {modifier.modifier_choice_list.map((choice) => (
                      <div className="grid grid-cols-6 pl-2" key={choice.external_modifier_choice_id}>
                        <div className="col-span-4">
                          {choice.quantity > 1 ? <span className="font-bold">{choice.quantity}x </span> : null}
                          <span className="capitalize">{choice.display_choice_name_en}</span>
                        </div>
                        <div className="col-span-2 text-right mr-4">
                          {choice.price
                            ? utils.formatCurrency(Number(choice.price) * choice.quantity, product.currency_alpha_3)
                            : 'Free'}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
                {product.order_line_item_notes ? (
                  <span className="text-orange-300">Note: {product.order_line_item_notes}</span>
                ) : null}
              </ul>
            </div>
          </div>
          <div className="flex justify-between items-center">
            {showManualCombinationRecommendations &&
            manualCombinationRecommendation.some(
              (r) => r.item_id === product.item_id && !basketItemIds.includes(r.recommended_item_id),
            ) ? (
              <button
                type="button"
                className="flex gap-1"
                onClick={() => {
                  const fulfilmentTypeMenu = menu.map((category) => ({
                    ...category,
                    menu_items: category.menu_items
                      .filter(
                        (item) =>
                          item.item_price_list.length === 0 ||
                          orderCreationStore.orderTicket.integration_details.fulfillment_type_id === 0 ||
                          item.item_price_list.find(
                            (price) =>
                              price.fulfillment_type_id ===
                              orderCreationStore.orderTicket.integration_details.fulfillment_type_id,
                          ),
                      )
                      .map((item) => ({ ...item, item_category_id: category.item_category_id })),
                  }));

                  const selectedMenuCategory = fulfilmentTypeMenu
                    .flatMap((category) => category.menu_items)
                    .sort((a, b) => a.menu_item_name_en.localeCompare(b.menu_item_name_en))
                    .sort((a, b) => Number(a.excluded_p) - Number(b.excluded_p));
                  const nRecommendation = manualCombinationRecommendation.find((r) => r.item_id === product.item_id);
                  const rItem = selectedMenuCategory.find(
                    (item) => item.item_id === nRecommendation?.recommended_item_id,
                  );
                  console.log(
                    'Fulfillment Type Id:',
                    orderCreationStore.orderTicket.integration_details.fulfillment_type_id,
                  );
                  console.log('Fulfillment Type Menu:', fulfilmentTypeMenu);
                  console.log('Selected Menu Category:', selectedMenuCategory);
                  console.log('Manual Combinations:', manualCombinationRecommendation);
                  console.log('N Recommendation:', nRecommendation);
                  console.log('R Item:', rItem);
                  if (rItem) {
                    const mRecommendation = manualCombinationRecommendation.find((r) => r.item_id === product.item_id);
                    setRecommendationsStore({
                      open: true,
                      recommendations: mRecommendation ? [mRecommendation] : [],
                      recommendedItemIds: [rItem.item_id],
                    });
                  }
                }}
              >
                <img src={`${process.env.PUBLIC_URL}/iblinkx-ai.gif`} alt="" className="h-5 w-5" />
                View Recommendations
              </button>
            ) : (
              <div />
            )}
            {allowQuantityChange ? (
              <div className="flex justify-end gap-1.5 py-1.5 items-center">
                <button
                  type="button"
                  className="rounded-full flex justify-center items-center bg-gray-200 p-1 h-max"
                  onClick={() => onQuantityChange(product, 'remove')}
                >
                  <TrashIcon className="w-4 h-4 text-red-500" />
                </button>
                <div
                  className="flex gap-1 items-center rounded-full bg-gray-100 p-1"
                  style={{ backgroundColor: theme?.general?.cardBackgroundColor || '' }}
                >
                  <button type="button" onClick={() => onQuantityChange(product, -1)}>
                    <MinusCircle className="text-gray-400" />
                  </button>
                  <span className="font-semibold">{product.quantity}</span>
                  <button type="button" onClick={() => onQuantityChange(product, 1)}>
                    <PlusCircle className="text-gray-400" />
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <div
            className={`grid grid-cols-2 bg-gray-100 rounded-lg py-2 ${
              subTotalHidden ? 'hidden' : 'block'
            } border text-gray-500`}
            style={{
              backgroundColor: theme?.general?.backgroundColor || '',
              color: theme?.general?.textColor || '',
              borderColor: theme?.general?.backgroundColor || '',
            }}
          >
            <div className="ml-4">Item Total</div>
            <div className="text-right mr-4">
              {utils.formatCurrency(Number(product.subTotal), product.currency_alpha_3)}
            </div>
          </div>
        </div>
      ))}
      {/* {basket.offers_list.map((offer) => (
        <div key={offer.offer_id} className="pb-2 mt-2">
          <div className="grid pl-4">
            <div className="col-span-4">
              <span className="font-bold">{offer.quantity}x </span>
              <span className="font-bold capitalize">{offer.offer_menu_item_name}</span>
              <div className="col-span-2 text-right mr-4">
                {utils.formatCurrency(Number(product.subTotal), product.currency_alpha_3)}
                {offer.offer_price.toFixed(2)}
              </div>
              {offer.offer_section_and_choices_list.map((section) => (
                <div key={section.offer_section_id} className="col-span-2 mr-4">
                  <span className="font-bold capitalize">{section.offer_section_display_name}</span>
                  <ul>
                    {section.offer_section_line_items_list.map((product) => (
                      <div key={product.menu_item_id} className="mt-2">
                        <div className="grid grid-cols-6 pl-4">
                          <div className="col-span-4">
                            <span className="font-bold">{product.quantity}x </span>
                            <span className="capitalize">{product.menu_item_name}</span>
                          </div>
                        </div>
                        <ul>
                          {product.menu_line_item_modifier_list.map((modifier) => (
                            <div key={modifier.modifier_section_id} className="pl-4 ml-5">
                              <h5 className="mb-0 capitalize">{modifier.section_name}</h5>
                              {modifier.modifier_choice_list.map((choice) => (
                                <div className="grid grid-cols-6 pl-4" key={choice.modifier_choice_id}>
                                  <div className="col-span-4">
                                    <span className="font-bold">{choice.choice_count}x </span>
                                    <span className="capitalize">{choice.modifier_name}</span>
                                  </div>
                                  <div className="col-span-2 text-right mr-4">
                                    {choice.price
                                      {utils.formatCurrency(Number(product.subTotal), product.currency_alpha_3)}
                                      ? `${} ${(Number(choice.price) * choice.choice_count).toFixed(2)}`
                                      : 'Free'}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className={`grid grid-cols-2 bg-gray-100 rounded-lg py-4 mt-2 ${subTotalHidden ? 'hidden' : 'block'}`}>
            <div className="ml-4 text-gray-500">Subtotal</div>
            <div className="text-right mr-4 text-gray-500">
              {utils.formatCurrency(Number(product.subTotal), product.currency_alpha_3)}
              {} {(offer.subTotal || 0).toFixed(2)}
            </div>
          </div>
        </div>
      ))} */}
      {/* <div className={`grid grid-cols-2 bg-gray-100 rounded-lg py-4 mt-2 ${subTotalHidden ? 'hidden' : 'block'}`}>
        <div className="ml-4 text-gray-500">Subtotal</div>
        <div className="text-right mr-4 text-gray-500">
          {utils.formatCurrency(Number(product.subTotal), product.currency_alpha_3)}
          {(
            basket.order_line_items_list.map((item) => item.subTotal || 0).reduce((prev, curr) => prev + curr, 0) +
            basket.offers_list.map((offer) => offer.subTotal || 0).reduce((prev, curr) => prev + curr, 0)
          ).toFixed(2)}
        </div>
      </div> */}
    </div>
  );
};

BasketProductsList.defaultProps = {
  containerStyle: 'mt-2 overflow-auto pb-20',
  subTotalHidden: false,
  showManualCombinationRecommendations: false,
  allowQuantityChange: true,
};

export default BasketProductsList;
