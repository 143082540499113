import { FC } from 'react';
import { TMenuItem } from '../../../common/types';
import utils from '../../../common/services/utils.service';
import { TTheme } from '../../../common/types/common.type';

type Props = {
  menuItem: TMenuItem;
  imageSize: string | undefined;
  theme: TTheme;
  onAddMenuItem: (menuItem: TMenuItem) => void;
};

const RecommendationCarouselItem = ({ menuItem, imageSize = undefined, theme, onAddMenuItem }: Props) => {
  return (
    <button
      type="button"
      className="bg-white pb-4 rounded-lg p-4 shadow-md"
      style={{
        backgroundColor: theme?.general?.cardBackgroundColor || '',
      }}
      onClick={() => onAddMenuItem(menuItem)}
    >
      <div className="grid grid-cols-3 gap-2.5">
        <div className="col-span-1">
          <img
            src={
              menuItem?.menu_item_images && menuItem?.menu_item_images.length > 0
                ? menuItem?.menu_item_images[0]?.menu_item_image_url
                : `${process.env.PUBLIC_URL}/assets/products/empty_menu.png`
            }
            alt={menuItem.menu_item_name_en}
            className={`${imageSize} object-cover float-left rounded-lg h-20`}
            onError={(e) => {
              e.currentTarget.src = `/assets/products/empty_menu.png`;
            }}
          />
        </div>
        <div className="col-span-2 flex flex-col justify-between items-left">
          <div>
            <p className="capitalize text-lg md:text-xl truncate ">{menuItem.menu_item_name_en}</p>
          </div>
          <div className="flex justify-between mt-2">
            {menuItem?.menu_item_price !== -1 ? (
              <p className="font-semibold text-md md:text-lg mt-2" style={{ color: theme.general?.textColor || '' }}>
                {utils.formatCurrency(menuItem?.menu_item_price, menuItem.currency_alpha_3)}
              </p>
            ) : (
              <p className="font-semibold text-sm">BOGO</p>
            )}
          </div>
        </div>
      </div>
    </button>
  );
};

export default RecommendationCarouselItem;
