import api, { TApiCustomResponse, TApiResponse } from '../../config/api/api.config';
import { TCity, TDiningArea, TMerchantBranch, TMerchantTransactionResponse, TOrderTicket } from './types';

type TLoginPayload = {
  username: string;
  password: string;
  merchant_code: string;
};

export type TSignupPayload = {
  first_names_en: null;
  last_name_en: null;
  phone_nr: string;
  email: null;
  password: string;
  merchant_code: string;
  device_information: {
    device_id: null;
    device_name: null;
    app_version: null;
  };
};

export type TUserDetails = {
  role_id: number;
  role_name: string;
  user_details: {
    user_id: number;
    first_name: string | null;
    last_name: string | null;
    phone_verified_p: number;
  };
  merchant: {
    merchant_id: number;
    merchant_type: {
      merchant_type_id: number;
      merchant_type_name: string;
    };
    merchant_website_url: string | null;
    merchant_code: string;
    merchant_name: string;
    merchant_email: string | null;
    merchant_logo_url: string | null;
    merchant_description: string | null;
    merchant_api_key: string;
    payment_link_expiry_duration: number | null;
    expiry_duration_measurement_id: number | null;
  };
  customer: {
    customer_id: number;
    user_customer_map_id: number;
    customer_code: string;
    stripe_customer_id: number | null;
    merchant_id: number;
    customer_first_name: string | null;
    customer_last_name: string | null;
    customer_email: string | null;
    customer_phone_nr: string;
    customer_remote_id: number | null;
    customer_gender: string | null;
    customer_dob: string | null;
  };
  action_name: string;
  status: string;
  message: string;
};

export type TGetMerchantBranchesPayload =
  | {
      latitude: string;
      longitude: string;
      fulfillment_type_id?: number;
      brand_id?: number;
    }
  | {
      city_id: number;
      brand_id?: number;
      fulfillment_type_id?: number;
    }
  | {
      branch_id: number;
    };

export type TMenuItemRecommendation = {
  menu_item_recommendation_id: number;
  item_id: number;
  customer_recommendation_p: number;
  server_recommendation_p: number;
  customer_message: string;
  customer_message_ar: string;
  server_message: string;
  server_message_ar: string;
  sequence_nr: number | null;
};

export type TManualPushRecommendation = TMenuItemRecommendation & {
  recommended_item_id: null;
  recommendation_type: 'manual-push';
};

export type TManualCombinationRecommendation = TMenuItemRecommendation & {
  recommended_item_id: number;
  recommendation_type: 'manual-combination';
};

export type TChefRecommendationPayload = {
  brand_id: number;
  menu_item_recommendation_type_map: {
    manual_push: TManualPushRecommendation[];
    manual_combination: TManualCombinationRecommendation[];
  };
};

const sharedApi = {
  login: (payload: TLoginPayload): TApiCustomResponse<TUserDetails> =>
    api.post('login', payload, {
      headers: {
        'x-access-token': undefined,
        'x-user-id': undefined,
        'x-api-key': undefined,
      },
    }),
  signup: (payload: TSignupPayload): TApiCustomResponse<TUserDetails> =>
    api.post('signup', payload, {
      headers: {
        'x-access-token': undefined,
        'x-user-id': undefined,
        'x-api-key': undefined,
      },
    }),
  getCities: (countryId: number): TApiResponse<TCity[]> => api.get(`cities?country_id==${countryId}`),
  getFacilityDiningAreas: (facilityId: number): TApiResponse<TDiningArea[]> =>
    api.get(`facility/${facilityId}/dining_areas`),
  getMerchantBranches: (payload: TGetMerchantBranchesPayload, merchantId: number): TApiResponse<TMerchantBranch[]> =>
    api.post(`merchant/${merchantId}/iblinkmarketplace`, payload),
  validateOrder: (orderTicket: TOrderTicket) => api.post(`calculate-order`, orderTicket),

  getMenuItemRecommendations: (
    merchantId: number,
    brandId: number,
  ): TApiResponse<{
    manual_push: TManualPushRecommendation[];
    manual_combination: TManualCombinationRecommendation[];
  }> => api.get(`menu-item-recommendations?merchant_id=${merchantId}&brand_id=${brandId}`),
  getMenuItemRecommendationByItemIds: (itemIds: number[]): TApiResponse<TManualCombinationRecommendation[]> =>
    api.post(`menu-item-recommendations/items`, {
      item_id_list: itemIds,
    }),
  generatePaymentLinkByOrderId: (customerOrderId: number): TApiCustomResponse<TMerchantTransactionResponse> =>
    api.get(`customer-order/${customerOrderId}/merchant-transaction`),
};

export default sharedApi;
