import { create } from 'zustand';

interface DefaultMenuItemState {
  defaultMenuItemId?: string | null;
  setDefaultMenuItemId: (menuItemId: string | null) => void;
}

const useDefaultMenuItem = create<DefaultMenuItemState>((set) => {
  const existingMenuItemId = localStorage.getItem('defaultMenuItemId');
  return {
    defaultMenuItemId: existingMenuItemId === '' ? null : existingMenuItemId,
    setDefaultMenuItemId: (menuItemId: string | null) => {
      localStorage.setItem('defaultMenuItemId', menuItemId || '');
      set((_state) => ({ defaultMenuItemId: menuItemId }));
    },
  };
});

export default useDefaultMenuItem;
