import { Tag } from 'antd';
import { TCustomerOrder } from '../../../common/types';
import useOrderCreationStore from '../../../hooks/useOrderCreation.hook';

type Props = {
  products: TCustomerOrder['item_list'];
  paymentDetail: undefined;
  offers: TCustomerOrder['offer_list'];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OrderProductsList = ({ products, paymentDetail, offers }: Props) => {
  const { orderCreationStore } = useOrderCreationStore();
  const { menu } = orderCreationStore;
  const currencySymbol = orderCreationStore?.orderTicket?.payment_details.currency_name?.toUpperCase() || 'AED';
  const flatItems = menu.flatMap((category) => category.menu_items);

  return (
    <div className="capitalize">
      {products.map((product) => (
        <div key={product.order_line_item_id_list[0]} className="relative mt-2">
          <div className="col-span-5 p-0 md:p-4 mt-2 md:w-full w-4/5">
            <div className="flex">
              <img
                src={
                  flatItems.find((item) => item.menu_item_external_id === product.external_item_id)
                    ?.menu_item_images?.[0]?.menu_item_image_url ||
                  `${process.env.PUBLIC_URL}/assets/products/empty_menu.png`
                }
                alt="menu item"
                className="h-10 w-10 md:h-16 md:w-16 object-cover rounded-lg neon-slate-light"
              />
              <div className="ml-2 md:ml-6 md:mt-2 inline-block">
                <h1 className="font-semibold">
                  {product.quantity || 1} x {product.display_name_en}{' '}
                  <Tag color="default">
                    {' '}
                    {currencySymbol} {product.price}{' '}
                  </Tag>
                </h1>
                {product.modifier_section_list.length > 0 && (
                  <>
                    {' '}
                    <h2 className="font-bold">Modifiers</h2>
                    <div className="text-sm">
                      {product.modifier_section_list.map((modifier) => (
                        <div key={modifier.external_modifier_section_id}>
                          <h3 className="font-bold">
                            {modifier.display_section_name_en}{' '}
                            {/* <Tag color="default">
                            {currencySymbol} {modifier.modifierSubtotal.toFixed(2)}
                          </Tag> */}
                          </h3>
                          {modifier.modifier_choice_list.map((choice) => (
                            <h3 key={choice.order_line_item_modifier_choice_id}>
                              {choice.quantity > 1
                                ? `${choice.quantity} x ${choice.display_choice_name_en}`
                                : `${choice.display_choice_name_en} `}
                              <Tag color="default">
                                {Number(choice.price) === 0
                                  ? 'Free'
                                  : `${currencySymbol} ${(Number(choice.price) * choice.quantity).toFixed(2)}`}
                              </Tag>
                            </h3>
                          ))}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="absolute md:right-2 right-0 top-2 font-semibold text-right">
            {currencySymbol}{' '}
            {(
              (Number(product.price) +
                Number(
                  product.modifier_section_list
                    .flatMap((section) => section.modifier_choice_list)
                    .flatMap((choice) => Number(choice.price) * choice.quantity)
                    .reduce((a, b) => a + b, 0),
                )) *
              (product.quantity || 1)
            ).toFixed(2)}
            {/* {currencySymbol} {(product.quantity * product.price).toFixed(2)} */}
          </div>
        </div>
      ))}
      {/* {paymentDetail && (
        <>
          <hr className="mx-2 md:mx-8 my-2" />
          <div className="grid grid-cols-4 p-2">
            <div className="ml-2 col-span-2 md:col-span-3" />
            <div className="col-span-2 md:col-span-1 text-right md:text-left grid grid-cols-2 whitespace-nowrap">
              <span className="mr-2">Sub Total:</span>
              <span className="font-bold text-base">
                {currencySymbol} {paymentDetail?.actual_payable_amount_total.toFixed(2)}
              </span>
              <span className="mr-2">Tax:</span>
              <span className="font-bold text-base">
                {currencySymbol} {paymentDetail?.taxes_amount.toFixed(2)}
              </span>
              <span className="mr-2 text-base">Total:</span>
              <span className="font-bold text-base">
                {currencySymbol}{' '}
                {((paymentDetail?.actual_payable_amount_total || 0) + (paymentDetail?.taxes_amount || 0)).toFixed(2)}
              </span>
            </div>
          </div>
        </>
      )} */}
    </div>
  );
};

export default OrderProductsList;
