import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { ConfigProvider } from 'antd';
import { useContext } from 'react';
import { Bounce, ToastContainer } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import RoutesContainer from './config/routes/routes-container/RoutesContainer.route';
import { nodeEnv } from './config/variables/system.variable';
import { ThemeContext, ThemeContextProvider } from './contexts/theme.context';
import { LoadingOverlayProvider } from './contexts/loading-overlay.context';
import { DataLoadingOverlay } from './modules/common/components';
import { useIsLoading } from './modules/common/hooks';

const queryClient = new QueryClient();

function App() {
  const { isLoadingStore } = useIsLoading();
  const { isLoading, content, className } = isLoadingStore;
  const { theme } = useContext(ThemeContext);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeContextProvider>
        <ConfigProvider
          theme={{
            components: {
              Message: {
                contentBg: '#653194',
              },
            },
          }}
        >
          <DataLoadingOverlay isLoading={isLoading} content={(content || undefined) as any} className={className} />
          <LoadingOverlayProvider>
            <Router>
              <RoutesContainer />
            </Router>
          </LoadingOverlayProvider>
        </ConfigProvider>
        <ToastContainer
          stacked
          position={isMobile ? 'bottom-center' : 'top-right'}
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Bounce}
          toastStyle={{
            borderRadius: '6px',
            backgroundColor: theme?.general?.buttonColor || '',
            color: theme?.header?.textColor || '',
          }}
        />
      </ThemeContextProvider>
      {nodeEnv === 'development' && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
}

export default App;
