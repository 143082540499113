import { useContext, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { Modal, notification } from 'antd';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { BasketProductsList, OtpModal } from '../../../sidemenu/components';
import { ThemeContext } from '../../../../contexts/theme.context';
import PhoneModal, { ModalHandle } from '../../../sidemenu/components/phone-modal/phone-modal.component';
import useOrderCreationStore from '../../../hooks/useOrderCreation.hook';
import { useIsLoading, useRecommendationModal } from '../../../common/hooks';
import sharedApi, { TManualPushRecommendation } from '../../../common/shared.api';
import { TMenuItem, TOrderLineItem, TBrandDetails } from '../../../common/types';
import 'react-toastify/dist/ReactToastify.css';
import { recommendationsToMenuItems } from '../../../common/services/utils.service';
import RecommendationsCarousel from '../../../sidemenu/components/recommendation-carousel/recomendation-carousel.component';

type BasketViewProps = {
  hideDrawer?: () => void;
};

const BasketView = ({ hideDrawer }: BasketViewProps) => {
  const { theme } = useContext(ThemeContext);
  const { orderCreationStore, addLineItemToTicket } = useOrderCreationStore();
  const brandDetails: TBrandDetails | null = JSON.parse(localStorage.getItem('brandDetails') || 'null');
  const { menu } = orderCreationStore;
  const { orderTicket } = orderCreationStore;
  const currencySymbol = orderTicket.payment_details.currency_name?.toUpperCase();
  const phoneModalRef = useRef<ModalHandle>(null);
  const otpModalRef = useRef<ModalHandle>(null);
  const navigate = useNavigate();
  const orderType = localStorage.getItem('orderType') || 'delivery';
  const { setRecommendationsStore } = useRecommendationModal();
  const [checkoutRecommendations, setCheckoutRecommendations] = useState<TManualPushRecommendation[]>([]);
  const [showedCheckoutRecommendations, setShowedCheckouRecommendations] = useState(false);
  const { setIsLoading } = useIsLoading();

  const orderTicketItems = orderTicket.item_list.map((item) => item.item_id);

  const checkoutPressed = () => {
    if (!showedCheckoutRecommendations && checkoutRecommendations.length > 0) {
      const recommendation = checkoutRecommendations[Math.floor(Math.random() * checkoutRecommendations.length)];
      setRecommendationsStore({
        open: true,
        recommendations: [recommendation],
        recommendedItemIds: [recommendation.item_id],
      });
      setShowedCheckouRecommendations(true);
      return;
    }

    if (orderType === 'dine-in') {
      hideDrawer?.();
      navigate('/checkout');
    } else {
      const iBlinkMarketplaceCustomer = JSON.parse(localStorage.getItem('iBlinkMarketplaceUser') || 'null');
      if (iBlinkMarketplaceCustomer) {
        Modal.confirm({
          title: 'Continue with current user?',
          content: `You are already logged in with phone number ${iBlinkMarketplaceCustomer?.phoneNumber} . Do you want to continue with this mobile number?`,
          okText: 'Yes',
          cancelText: 'No',
          onOk: () => {
            hideDrawer?.();
            navigate('/checkout');
          },
          onCancel: () => {
            localStorage.removeItem('iBlinkMarketplaceUser');
            localStorage.removeItem('phoneNumber');
            phoneModalRef.current?.toggleModal();
          },
        });
      } else phoneModalRef.current?.toggleModal();
    }
  };

  const phoneContinuePressed = (userAlreadyExists = false) => {
    if (userAlreadyExists) {
      hideDrawer?.();
      navigate('/checkout');
    } else otpModalRef.current?.toggleModal();
  };

  const otpContinuePressed = () => {
    hideDrawer?.();
    navigate('/checkout');
  };

  const checkMenuItemRecommendation = async () => {
    const { data, status } = await sharedApi.getMenuItemRecommendations(
      brandDetails?.merchant.merchant_id || 0,
      orderTicket.integration_details.brand_id || 0,
    );

    if (status === 200 && data.status === 'successful' && data.data) {
      console.log('Manual push data:', data.data);
      setCheckoutRecommendations(
        data.data.manual_push.filter((r) => r.customer_recommendation_p && !orderTicketItems.includes(r.item_id)),
      );
    }
  };

  const showNotification = (menuItem: TMenuItem) => {
    toast.info(
      <div>
        <img
          src={
            menuItem?.menu_item_images && menuItem?.menu_item_images.length > 0
              ? menuItem?.menu_item_images[0]?.menu_item_image_url
              : `${process.env.PUBLIC_URL}/assets/products/empty_menu.png`
          }
          alt="product"
          className="h-16 w-16 object-cover float-left rounded-lg"
          onError={(e) => {
            e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/products/empty_menu.png`;
          }}
        />
        <div className="ml-20 capitalize">
          <b>{menuItem?.menu_item_name_en}</b>
        </div>
        <div className="ml-20">(X1) Added to basket</div>
      </div>,
    );
  };

  const onAddMenuItem = async (item: TMenuItem) => {
    setIsLoading(true);
    const orderLineItem: TOrderLineItem = {
      id: uuidv4(),
      external_item_id: item.menu_item_external_id,
      item_id: item.item_id,
      item_category_id: item.item_category_id || 0,
      display_name_en: item.menu_item_name_en,
      display_name_ar: item.menu_item_name_ar,
      menu_item_image: item.menu_item_images.length > 0 ? item.menu_item_images[0].menu_item_image_url : '',
      item_price: item.menu_item_price,
      price: item.menu_item_price,
      item_price_list: item.item_price_list || [],
      quantity: 1,
      modifier_section_list: [],
      modifierSubtotal: 0,
      currency_alpha_3: item.currency_alpha_3,
    };
    if (item.modifier_sections.length === 0) {
      const itemAdded = await addLineItemToTicket(orderLineItem);
      if (itemAdded) {
        showNotification(item);

        // checkMenuItemRecommendation(item); // ! TODO: check if this is needed
      } else
        notification.error({
          message: 'Something went wrong',
          description: `Unable to add ${item.menu_item_name_en} to the basket, please try again later`,
          placement: isMobile ? 'bottom' : 'topRight',
          duration: 1.5,
        });
    } else navigate(`/product/${item.item_id}`);
    setIsLoading(false);
  };

  useEffect(() => {
    if (orderTicket?.integration_details?.brand_id) {
      checkMenuItemRecommendation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTicket.integration_details.brand_id]);

  return (
    <>
      <PhoneModal ref={phoneModalRef} continuePressed={phoneContinuePressed} />
      <OtpModal ref={otpModalRef} continuePressed={otpContinuePressed} />
      {orderTicket.item_list && (
        <div
          className="py-4 bg-gray-50 w-full relative md:static h-fill overflow-y-auto md:pb-24 pb-60"
          style={{
            backgroundColor: theme?.general?.backgroundColor || '',
            color: theme?.general?.textColor || '',
          }}
        >
          {/* <div className="p-4 bg-white">
          <HorizontalSlider
            title="You May Also Like"
            titleSize="text-lg"
            imagesArray={relatedProducts}
            spacing="grid-cols-[repeat(auto-fill,minmax(60px,1fr))] grid-flow-col auto-cols-[minmax(60px,1fr)]"
            imageContainerSize="h-24 w-16"
            imageSize="h-16 w-16"
            showImageName
          />
        {/* <div className="grid grid-cols-2 bg-white p-6 mt-4">
          <div className="text-md">Cutlery</div>
          <div className="flex justify-end">
            <Switch />
          </div>
        </div> */}
          {/* <div className="flex bg-white mt-2">
            <input type="text" placeholder="Order Notes" className="px-6 py-4 w-full border-none" />
          </div> */}

          {checkoutRecommendations.length > 0 && (
            <div className="mb-8">
              <p className="text-2xl font-semibold mx-4 mb-4">We also recommend</p>
              <RecommendationsCarousel
                recommendations={recommendationsToMenuItems(
                  checkoutRecommendations.map((rec) => rec.item_id),
                  menu,
                  orderCreationStore.orderTicket.integration_details.fulfillment_type_id,
                )}
                theme={theme}
                onAddMenuItem={onAddMenuItem}
              />
            </div>
          )}

          <p className="text-2xl font-semibold mx-4 mb-4">Your items</p>
          <BasketProductsList
            containerStyle={window.location.pathname === '/basket' ? 'pb-40' : undefined}
            showManualCombinationRecommendations
          />

          <div
            className="fixed md:absolute rounded-t-md bg-white bottom-0 left-0 right-0 border-t pt-2"
            style={{
              backgroundColor: theme?.general?.cardBackgroundColor || '',
              borderColor: theme?.general?.backgroundColor || '',
            }}
          >
            <div className="grid grid-cols-2 py-1">
              <div className="ml-4">Sub Total</div>
              <div className="text-right mr-4 font-bold">
                {currencySymbol}{' '}
                {(
                  orderTicket.payment_details.payable_amount +
                  orderTicket.payment_details.discount_amount -
                  orderTicket.payment_details.delivery_amount
                ).toFixed(2)}
              </div>
            </div>
            {orderTicket.payment_details.delivery_amount ? (
              <div className="grid grid-cols-2 py-1">
                <div className="ml-4">Delivery Amount</div>
                <div className="text-right mr-4 font-bold">
                  {currencySymbol} {orderTicket.payment_details.delivery_amount.toFixed(2)}
                </div>
              </div>
            ) : null}
            <div className="grid grid-cols-2 py-1">
              <div className="ml-4">Grand Total</div>
              <div className="text-right mr-4 font-bold">
                {currencySymbol} {orderTicket.payment_details.payable_amount.toFixed(2)}
              </div>
            </div>
            <div className="flex justify-center my-4">
              <button
                type="button"
                disabled={
                  (orderTicket.item_list.length === 0 && orderTicket.offer_list.length === 0) ||
                  orderTicket.payment_details.payable_amount < 0
                }
                onClick={checkoutPressed}
                className="bg-colorPrimary text-center rounded-md w-[90%] py-3 text-textPrimary text-lg font-semibold disabled:opacity-50"
                style={{ backgroundColor: theme?.general?.buttonColor || '' }}
              >
                Go To Checkout
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

BasketView.defaultProps = {
  hideDrawer: () => {},
};

export default BasketView;
