import { Modal, notification } from 'antd';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { isMobile } from 'react-device-detect';
import api from '../../../../config/api/api.config';
import { ThemeContext } from '../../../../contexts/theme.context';
import { DataLoadingOverlay } from '../../../common/components';
import sharedApi from '../../../common/shared.api';
import { TBrandDetails } from '../../../common/types';
import sideMenuApi from '../../sidemenu.api';

export type ModalHandle = {
  toggleModal: () => void;
};

type PhoneModalProps = {
  continuePressed: (userAlreadyExists?: boolean) => void;
};

const PhoneModal = React.forwardRef<ModalHandle, PhoneModalProps>(({ continuePressed }, ref) => {
  const { theme } = useContext(ThemeContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const brandDetails: TBrandDetails | null = JSON.parse(localStorage.getItem('brandDetails') || 'null');

  useImperativeHandle(ref, () => ({
    toggleModal: () => {
      setIsModalVisible((prev) => !prev);
    },
  }));
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = async () => {
    if (!brandDetails) {
      console.error('Cannot login without brand details');
      return;
    }
    setIsLoading(true);
    const { data, status, headers } = await sharedApi.login({
      username: phoneNumber,
      password: '123456',
      merchant_code: brandDetails.merchant.merchant_code,
    });

    if (status === 200 && data.status === 'successful') {
      localStorage.setItem('phoneNumber', phoneNumber);
      localStorage.setItem('customerDetails', JSON.stringify(data));
      const iBlinkMarketplaceCustomer = {
        token: headers['x-access-token'],
        id: headers['x-user-id'],
        apiKey: data.merchant.merchant_api_key,
        phoneNumber,
      };
      localStorage.setItem('iBlinkMarketplaceUser', JSON.stringify(iBlinkMarketplaceCustomer));
      api.setAuthenticationHeader(iBlinkMarketplaceCustomer);

      const currentUserUpdated = new CustomEvent('currentUserUpdated', { detail: data });
      window.dispatchEvent(currentUserUpdated);
      setIsModalVisible(false);
      continuePressed(true);
    } else {
      const { data: signupData, status: signupStatus } = await sharedApi.signup({
        first_names_en: null,
        last_name_en: null,
        phone_nr: phoneNumber,
        email: null,
        password: '123456',
        merchant_code: brandDetails.merchant.merchant_code,
        device_information: {
          device_id: null,
          device_name: null,
          app_version: null,
        },
      });

      if (signupStatus === 200 && signupData.status === 'successful') {
        localStorage.setItem('phoneNumber', phoneNumber);
        setIsModalVisible(false);
        continuePressed();
      } else if (signupData.message === 'phone number already exists.') {
        const { data: requestData, status: requestStatus } = await sideMenuApi.requestOPT({
          phone_nr: phoneNumber,
          email: null,
          intent: 'customer_signup', // customer_signup
          contact_method: 'sms',
          merchant_code: brandDetails.merchant.merchant_code,
        });
        if (requestData.status === 'successful' && requestStatus === 200) {
          notification.success({
            message: 'OTP Sent',
            description: 'OTP has been sent to your phone number',
            placement: isMobile ? 'bottom' : 'topRight',
            duration: 1,
          });
          localStorage.setItem('phoneNumber', phoneNumber);
          setIsModalVisible(false);
          continuePressed();
        } else {
          notification.error({
            message: 'Something went wrong',
            description: 'Unable to send OTP, please try again later',
            placement: isMobile ? 'bottom' : 'topRight',
            duration: 1.5,
          });
        }
      } else
        notification.error({
          message: 'Something went wrong',
          description: `Unable to signup, please try again later`,
          placement: isMobile ? 'bottom' : 'topRight',
          duration: 1.5,
        });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isModalVisible) setPhoneNumber(undefined);
  }, [isModalVisible]);

  return (
    <Modal title={null} centered width={300} open={isModalVisible} onCancel={handleCancel} footer={null}>
      <DataLoadingOverlay isLoading={isLoading} />
      <div className="mt-6">
        <h1 className="text-center font-bold text-base">Enter Phone Number</h1>
        <div className="text-center bg-white p-2 mt-4 mb-2 rounded-lg">
          <PhoneInput
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={setPhoneNumber}
            defaultCountry="AE"
          />
        </div>
        <div className="flex justify-center my-4">
          <button
            disabled={!isValidPhoneNumber(phoneNumber || '')}
            type="button"
            onClick={handleOk}
            className="bg-colorPrimary text-center rounded-full w-[90%] py-2 text-textPrimary text-lg font-semibold disabled:opacity-50"
            style={{ backgroundColor: theme?.general?.buttonColor || '' }}
          >
            Continue
          </button>
        </div>
      </div>
    </Modal>
  );
});
PhoneModal.displayName = 'PhoneModal';

export default PhoneModal;
